const venuesData = [
    { 
        "name": "Le Grand Vefour",
        "address": "16 Rue Saint-Augustin, 75002 Paris",
        "description": "A historic Parisian restaurant located in the heart of the Palais-Royal, Le Grand Vefour has been serving gourmet French cuisine since 1784. It’s known for its opulent décor and a legacy of serving famous patrons.",
        "website": "legrand-vefour.com",
        "lat": 48.8661, 
        "lng": 2.3362,
        "type": "Restaurant"
    },
    { 
        "name": "Hotel Plaza Athénée",
        "address": "12 Rue Marbeuf, 75008 Paris",
        "description": "A luxurious hotel on Avenue Montaigne, offering lavish accommodations, a Dior spa, and fine dining options. It's a favorite among celebrities and fashionistas.",
        "website": "dorchestercollection.com",
        "lat": 48.8669, 
        "lng": 2.3041,
        "type": "Hotel"
    },
    { 
        "name": "Le Petit Poucet",
        "address": "94 rue des Dames, 75017 Paris",
        "description": "A cozy bistro offering traditional French dishes in a charming setting. It's popular among locals for its relaxed atmosphere and hearty meals.",
        "website": null,
        "lat": 48.8845, 
        "lng": 2.3169,
        "type": "Bistro"
    },
    { 
        "name": "Le Grand Colbert",
        "address": "6 Rue Coquillière, 75001 Paris",
        "description": "This historic café is famous for its classic Parisian brasserie ambiance and has been featured in films. It serves traditional French cuisine in a beautiful Belle Époque setting.",
        "website": "legrandcolbert.fr",
        "lat": 48.8636, 
        "lng": 2.3410,
        "type": "Brasserie"
    },
    { 
        "name": "Le Petit Châtelet",
        "address": "9 Rue du Trésor, 75004 Paris",
        "description": "A charming restaurant offering traditional French cuisine in a warm, rustic setting. Located near the Seine, it's a great spot for a cozy meal.",
        "website": null,
        "lat": 48.8529, 
        "lng": 2.3498,
        "type": "Restaurant"
    },
    { 
        "name": "Le Consulat",
        "address": "181 rue Ordener, 75018 Paris",
        "description": "A historic restaurant in Montmartre, known for its charming, old-world atmosphere. It’s a favorite among artists and tourists alike.",
        "website": null,
        "lat": 48.8935, 
        "lng": 2.3444,
        "type": "Restaurant"
    },
    { 
        "name": "Le Relais Montmartre",
        "address": "4 Rue Lallier, 75009 Paris",
        "description": "A boutique hotel offering cozy rooms in the heart of Montmartre, just a short walk from the Moulin Rouge.",
        "website": "hotel-relais-montmartre.com",
        "lat": 48.8829, 
        "lng": 2.3373,
        "type": "Hotel"
    },
    { 
        "name": "Le Marché d’Aligre",
        "address": "7 Rue Théophile Roussel, 75012 Paris",
        "description": "A vibrant, bustling market offering a wide variety of fresh produce, meats, cheeses, and antiques. It’s a local favorite for food shopping and browsing.",
        "website": null,
        "lat": 48.8487, 
        "lng": 2.3786,
        "type": "Market"
    },
    { 
        "name": "La Creperie",
        "address": "122 Rue Montmartre, 75002 Paris",
        "description": "A small, casual crêperie serving a variety of sweet and savory crêpes, perfect for a quick and delicious meal.",
        "website": null,
        "lat": 48.8692, 
        "lng": 2.3438,
        "type": "Creperie"
    },
    { 
        "name": "Le Petit Cambodge",
        "address": "182 Rue Saint-Maur, 75010 Paris",
        "description": "A popular restaurant offering Cambodian-inspired cuisine with a focus on fresh, flavorful dishes.",
        "website": null,
        "lat": 48.8720, 
        "lng": 2.3694,
        "type": "Restaurant"
    },
    { 
        "name": "Chanel",
        "address": "38 Rue Cambon, 75001 Paris",
        "description": "The iconic fashion house founded by Coco Chanel, offering luxury fashion, accessories, and beauty products. The Rue Cambon store is a must-visit for fashion enthusiasts.",
        "website": "chanel.com",
        "lat": 48.8671, 
        "lng": 2.3265,
        "type": "Luxury Store"
    },
    { 
        "name": "Le Comptoir Général",
        "address": "48 rue du Dessous des Berges, 75013 Paris",
        "description": "A unique venue combining a café, bar, and cultural space. It's known for its eclectic décor and relaxed vibe.",
        "website": "lecomptoirgeneral.com",
        "lat": 48.8274, 
        "lng": 2.3684,
        "type": "Café/Bar/Cultural Space"
    },
    { 
        "name": "Marché des Enfants Rouges",
        "address": "39 Rue de Bretagne, 75003 Paris",
        "description": "The oldest covered market in Paris, offering a variety of food stalls with international cuisine, fresh produce, and local specialties.",
        "website": null,
        "lat": 48.8635, 
        "lng": 2.3622,
        "type": "Market"
    },
    { 
        "name": "Le Comptoir du Relais",
        "address": "25 Rue du Dragon, 75006 Paris",
        "description": "A beloved bistro by chef Yves Camdeborde, known for its modern take on classic French cuisine in a bustling, friendly setting.",
        "website": null,
        "lat": 48.8528, 
        "lng": 2.3325,
        "type": "Bistro"
    },
    { 
        "name": "Hotel Raphael",
        "address": "958 Rue de Bassano, 75008 Paris",
        "description": "A luxurious hotel with classic Parisian elegance, located near the Arc de Triomphe. It’s known for its rooftop terrace with stunning views of Paris.",
        "website": "raphael-hotel.com",
        "lat": 48.8736, 
        "lng": 2.2945,
        "type": "Hotel"
    },
    { 
        "name": "Le Comptoir du Relais",
        "address": "173 boulevard Saint Germain, 75006 Paris",
        "description": "This iconic bistro offers traditional French fare with a modern twist in the lively Saint Germain district.",
        "website": null,
        "lat": 48.8544, 
        "lng": 2.3342,
        "type": "Bistro"
    },
    { 
        "name": "Musée de la Vie Romantique",
        "address": "16 rue Chaptal, 75009 Paris",
        "description": "A museum dedicated to the Romantic era, showcasing art, literature, and memorabilia in a charming 19th-century house.",
        "website": "museevieromantique.paris.fr",
        "lat": 48.8780, 
        "lng": 2.3345,
        "type": "Museum"
    },
    { 
        "name": "Le Consulat",
        "address": "41 Rue des Trois Frères, 75018 Paris",
        "description": "Another location of this historic Montmartre café, offering a taste of Parisian history and culture.",
        "website": null,
        "lat": 48.8861, 
        "lng": 2.3388,
        "type": "Café"
    },
    { 
        "name": "Le Grand Colbert",
        "address": "2 Rue de Viarmes, 75001 Paris",
        "description": "A classic Parisian brasserie with a rich history and elegant Belle Époque décor, serving traditional French cuisine.",
        "website": "legrandcolbert.fr",
        "lat": 48.8617, 
        "lng": 2.3427,
        "type": "Brasserie"
    },
    { 
        "name": "(Private residence)",
        "address": "20 bis Rue de Douai, 75009 Paris",
        "description": "This address corresponds to a private residence, and as such, there is no public venue here.",
        "website": null,
        "lat": 48.8825, 
        "lng": 2.3323,
        "type": "Private Residence"
    },
    { 
        "name": "Le Café de l’Esplanade",
        "address": "29 Rue de Varenne, 75007 Paris",
        "description": "A stylish café located near the Musée Rodin, offering a menu of French classics in an elegant setting.",
        "website": null,
        "lat": 48.8573, 
        "lng": 2.3181,
        "type": "Café"
    },
    { 
        "name": "Le Petit Zinc",
        "address": "11 Rue Saint-Benoît, 75006 Paris",
        "description": "A charming restaurant with an Art Nouveau décor, serving classic French dishes in the heart of Saint-Germain-des-Prés.",
        "website": null,
        "lat": 48.8545, 
        "lng": 2.3338,
        "type": "Restaurant"
    },
    { 
        "name": "Le Fumoir",
        "address": "6 Rue de l’Amiral de Coligny, 75001 Paris",
        "description": "A chic restaurant and bar near the Louvre, known for its stylish atmosphere and contemporary French cuisine.",
        "website": null,
        "lat": 48.8606, 
        "lng": 2.3417,
        "type": "Restaurant/Bar"
    },
    { 
        "name": "Le Train Bleu",
        "address": "Place Louis-Armand, 75012 Paris",
        "description": "An opulent restaurant located within the Gare de Lyon, offering gourmet French cuisine in a historic and luxurious setting.",
        "website": "le-train-bleu.com",
        "lat": 48.8443, 
        "lng": 2.3752,
        "type": "Restaurant"
    },
    { 
        "name": "Le Procope",
        "address": "13 Rue de l’Ancienne Comédie, 75006 Paris",
        "description": "The oldest café in Paris, dating back to 1686, Le Procope is a historic venue where many famous intellectuals have gathered over the centuries.",
        "website": "procope.com",
        "lat": 48.8533, 
        "lng": 2.3380,
        "type": "Café/Restaurant"
    }
]


  // You can add more venues here in the future
;

export default venuesData;