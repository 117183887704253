import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams, useNavigate } from 'react-router-dom';
import { Map, Brain, Home, Camera, ChevronLeft, ChevronRight, ArrowLeft, ExternalLink } from 'lucide-react';

import ParisAddresses from './ParisAddresses';
import 'leaflet/dist/leaflet.css';

const ITEMS_PER_PAGE = 10;
const PAGINATION_BLOCKS = 10;

const App = () => {
  return (
    <Router>
      <div className="h-screen flex flex-col bg-gray-100 overflow-hidden">
        <Routes>
          <Route path="/" element={<ScreenshotGallery />} />
          <Route path="/screenshot/:id" element={<ScreenshotPage />} />
          <Route path="/paris-map" element={<ParisAddresses />} />
        </Routes>
      </div>
    </Router>
  );
};

const ScreenshotGallery = () => {
  const [screenshots, setScreenshots] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const importAllScreenshots = () => {
      const context = require.context('../public/screenshots', false, /\.png$/);
      return context.keys().map(key => ({
        id: key.replace('./', '').replace('.png', ''),
        src: `/screenshots${key.substring(1)}`,
        alt: `Screenshot ${key.split('_')[0].split('T')[1]}`
      }));
    };

    setScreenshots(importAllScreenshots());
  }, []);

  const totalPages = Math.ceil(screenshots.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentScreenshots = screenshots.slice(startIndex, endIndex);

  const goToPage = (page) => setCurrentPage(page);
  const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const goToNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  const renderPaginationBlocks = () => {
    const startBlock = Math.max(1, Math.min(currentPage - Math.floor(PAGINATION_BLOCKS / 2), totalPages - PAGINATION_BLOCKS + 1));
    const endBlock = Math.min(startBlock + PAGINATION_BLOCKS - 1, totalPages);

    return Array.from({ length: endBlock - startBlock + 1 }, (_, i) => startBlock + i).map((page) => (
      <button
        key={page}
        onClick={() => goToPage(page)}
        className={`w-8 h-8 flex items-center justify-center border rounded-md mx-1 ${
          page === currentPage
            ? 'bg-blue-500 text-white'
            : 'bg-white text-blue-500 hover:bg-blue-100'
        }`}
      >
        {page}
      </button>
    ));
  };

  return (
    <>
      <nav className="bg-white shadow-md p-4">
        <div className="flex justify-between items-center mb-4">
          <NavIcon Icon={Home} label="Home" to="/" />
          <NavIcon Icon={Map} label="Paris Map" to="/paris-map" />
          <NavIcon Icon={Brain} label="AI Agent" />
          <NavIcon Icon={Camera} label="Camera" />
        </div>
        <div className="flex justify-center items-center overflow-x-auto">
          <button onClick={goToPreviousPage} disabled={currentPage === 1} className="mr-2">
            <ChevronLeft className="w-5 h-5" />
          </button>
          {renderPaginationBlocks()}
          <button onClick={goToNextPage} disabled={currentPage === totalPages} className="ml-2">
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </nav>
      
      <main className="flex-grow overflow-y-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Screenshots</h1>
        <div className="space-y-4">
          {currentScreenshots.map((screenshot) => (
            <Link to={`/screenshot/${screenshot.id}`} key={screenshot.id} className="block">
              <div className="bg-white rounded-lg shadow-md overflow-hidden">
                <img 
                  src={screenshot.src} 
                  alt={screenshot.alt} 
                  className="w-full h-auto"
                />
              </div>
            </Link>
          ))}
        </div>
      </main>
    </>
  );
};

const ScreenshotPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [screenshot, setScreenshot] = useState(null);

  useEffect(() => {
    const screenshotSrc = `/screenshots/${id}.png`;
    const instagramLink = extractInstagramLink(id);
    setScreenshot({ id, src: screenshotSrc, alt: `Screenshot ${id}`, instagramLink });
  }, [id]);

  const extractInstagramLink = (filename) => {
    const regex = /(\d{4}-\d{2}-\d{2}T\d{2}-\d{2}-\d{2}\.\d{3}Z)_(www\.instagram\.com)_(.+)/;
    const match = filename.match(regex);
    if (match) {
      const [, , domain, path] = match;
      return `https://${domain}/${path.replace(/_/g, '/')}`;
    }
    return null;
  };

  if (!screenshot) return <div>Loading...</div>;

  return (
    <div className="h-full flex flex-col">
      <nav className="bg-white shadow-md p-6">
        <div className="flex items-center">
          <button 
            onClick={() => navigate(-1)} 
            className="flex items-center text-blue-500 text-2xl font-bold"
          >
            <ArrowLeft className="w-8 h-8 mr-3" />
            Back to Gallery
          </button>
        </div>
      </nav>
      <main className="flex-grow overflow-y-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Screenshot {id}</h1>
        <div className="bg-white rounded-lg shadow-md overflow-hidden mb-4">
          <img 
            src={screenshot.src} 
            alt={screenshot.alt} 
            className="w-full h-auto"
          />
        </div>
        {screenshot.instagramLink && (
          <a 
            href={screenshot.instagramLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
          >
            View on Instagram
            <ExternalLink className="w-4 h-4 ml-2" />
          </a>
        )}
      </main>
    </div>
  );
};

const NavIcon = ({ Icon, label, to }) => (
  <Link to={to} className="flex flex-col items-center">
    <Icon className="w-6 h-6" />
    <span className="text-xs mt-1">{label}</span>
  </Link>
);

export default App;