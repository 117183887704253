import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, ZoomControl, useMap } from 'react-leaflet';
import L from 'leaflet';
import { Search, ArrowLeft, Navigation, ChevronLeft, ChevronRight, User, Car, Bus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import venuesData from './venueData';
import 'leaflet/dist/leaflet.css';
import './ParisAddresses.css';

const createCustomIcon = (color, size) => new L.Icon({
  iconUrl: `https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [size, size * 1.5],
  iconAnchor: [size / 2, size * 1.5],
  popupAnchor: [0, -size * 1.5 / 2],
  shadowSize: [size * 1.5, size * 1.5]
});

const customIcon = createCustomIcon('blue', 25);
const highlightedIcon = createCustomIcon('red', 35);

// Opera coordinates (simulating user's location)
const USER_LOCATION = [48.8709, 2.3317];

const MapController = ({ selectedVenue }) => {
  const map = useMap();
  
  useEffect(() => {
    if (selectedVenue) {
      map.setView([selectedVenue.lat, selectedVenue.lng], 15);
    }
  }, [selectedVenue, map]);

  return null;
};

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
  ; 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  const d = R * c; // Distance in km
  return d;
};

const deg2rad = (deg) => {
  return deg * (Math.PI/180);
};

const getDirectionsUrl = (destLat, destLng) => {
  return `https://www.google.com/maps/dir/?api=1&origin=${USER_LOCATION[0]},${USER_LOCATION[1]}&destination=${destLat},${destLng}&travelmode=walking`;
};

// Simulated travel time calculation
const calculateTravelTimes = (distance) => {
  const walkingSpeed = 5; // km/h
  const uberSpeed = 30; // km/h
  const publicTransportSpeed = 20; // km/h

  const walkingTime = Math.round((distance / walkingSpeed) * 60);
  const uberTime = Math.round((distance / uberSpeed) * 60);
  const publicTransportTime = Math.round((distance / publicTransportSpeed) * 60);

  return {
    walking: walkingTime,
    uber: uberTime,
    publicTransport: publicTransportTime
  };
};

const ParisAddresses = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredVenues, setFilteredVenues] = useState(venuesData);
  const [mapboxToken, setMapboxToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedVenueIndex, setSelectedVenueIndex] = useState(null);
  const mapRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    if (token) {
      setMapboxToken(token);
    } else {
      setErrorMessage('Mapbox access token not found in environment variables');
    }
  }, []);

  useEffect(() => {
    setFilteredVenues(
      venuesData.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.type.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  const handleVenueSelect = (index) => {
    setSelectedVenueIndex(index);
  };

  const handleBackClick = () => {
    navigate('/');
  };

  const handlePrevVenue = () => {
    setSelectedVenueIndex((prevIndex) => 
      prevIndex > 0 ? prevIndex - 1 : filteredVenues.length - 1
    );
  };

  const handleNextVenue = () => {
    setSelectedVenueIndex((prevIndex) => 
      prevIndex < filteredVenues.length - 1 ? prevIndex + 1 : 0
    );
  };

  if (errorMessage) return <div>Error: {errorMessage}</div>;
  if (!mapboxToken) return <div>Loading...</div>;

  return (
    <div className="paris-addresses-container">
      <div className="map-header">
        <button className="back-button" onClick={handleBackClick}>
          <ArrowLeft size={24} />
        </button>
        <div className="search-bar">
          <Search className="search-icon" />
          <input
            type="text"
            placeholder="Search venues..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="map-section">
          <MapContainer 
            center={USER_LOCATION} 
            zoom={13} 
            zoomControl={false} 
            style={{ height: '100%', width: '100%' }}
            ref={mapRef}
          >
            <TileLayer
              url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${mapboxToken}`}
              attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
              id="mapbox/streets-v11"
            />
            <ZoomControl position="bottomright" />
            <MapController selectedVenue={filteredVenues[selectedVenueIndex]} />
            {filteredVenues.map((venue, index) => (
              <Marker 
                key={index} 
                position={[venue.lat, venue.lng]} 
                icon={index === selectedVenueIndex ? highlightedIcon : customIcon}
                eventHandlers={{
                  click: () => handleVenueSelect(index),
                }}
              />
            ))}
          </MapContainer>
          {selectedVenueIndex !== null && (
            <div className="venue-info-container">
              <button className="nav-button prev" onClick={handlePrevVenue}>
                <ChevronLeft size={24} />
              </button>
              <div className="venue-info-box">
                <h3>{filteredVenues[selectedVenueIndex].name}</h3>
                <p className="venue-type">{filteredVenues[selectedVenueIndex].type}</p>
                <p className="venue-description">{filteredVenues[selectedVenueIndex].description}</p>
                {(() => {
                  const distance = calculateDistance(
                    USER_LOCATION[0], USER_LOCATION[1], 
                    filteredVenues[selectedVenueIndex].lat, filteredVenues[selectedVenueIndex].lng
                  );
                  const times = calculateTravelTimes(distance);
                  return (
                    <div className="travel-info">
                      <div className="distance">
                        <Navigation size={16} />
                        <span>{distance.toFixed(1)} km away</span>
                      </div>
                      <div className="travel-times">
                        <div className="travel-mode">
                          <User size={16} />
                          <span>{times.walking} min</span>
                        </div>
                        <div className="travel-mode">
                          <Car size={16} />
                          <span>{times.uber} min</span>
                        </div>
                        <div className="travel-mode">
                          <Bus size={16} />
                          <span>{times.publicTransport} min</span>
                        </div>
                      </div>
                    </div>
                  );
                })()}
                <a 
                  href={getDirectionsUrl(filteredVenues[selectedVenueIndex].lat, filteredVenues[selectedVenueIndex].lng)} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="directions-link"
                >
                  Get Directions
                </a>
              </div>
              <button className="nav-button next" onClick={handleNextVenue}>
                <ChevronRight size={24} />
              </button>
            </div>
          )}
        </div>
        <div className="venue-list-section">
          <ul>
            {filteredVenues.map((venue, index) => {
              const distance = calculateDistance(USER_LOCATION[0], USER_LOCATION[1], venue.lat, venue.lng);
              const times = calculateTravelTimes(distance);
              return (
                <li 
                  key={index} 
                  onClick={() => handleVenueSelect(index)}
                  className={index === selectedVenueIndex ? 'selected' : ''}
                >
                  <strong>{venue.name}</strong>
                  <p>{venue.type}</p>
                  <div className="list-travel-times">
                    <span><User size={12} /> {times.walking}m</span>
                    <span><Car size={12} /> {times.uber}m</span>
                    <span><Bus size={12} /> {times.publicTransport}m</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ParisAddresses;